import axios from 'axios';

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const header = () => ({
    'Content-Type': 'application/json',
});


const client = axios.create({
    baseURL: API_ENDPOINT,
});

class DataService {
    static get(path = '', params = {}) {
        return client({
            method: 'GET',
            url: path,
            params: params,
            headers: {...header()},
        });
    }

    static post(path = '', data = {}) {
        return client({
            method: 'POST',
            url: path,
            data,
            headers: {...header()},
        });
    }

}

export {DataService};
