import { createWebHistory, createRouter } from 'vue-router';
import authRoutes from './AuthRoutes';

const routes = [

  {
    name: 'Auth',
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAdminLayout.vue'),
    children: [...authRoutes],
    meta: { auth: false },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

export default router;
