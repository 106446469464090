export default {
    offerDataBegin(state) {
        state.loadingOffer = true;
        state.errorOffer = false;
    },
    offerDataSuccess(state, data) {
        data.product.customerApprovalsValidation = new Array(data.product.customerApprovals.length).fill(false);
        state.offer = data;
        state.loadingOffer = false;
    },
    offerDataErr(state, err) {
        state.loadingOffer = false;
        state.loadingOfferError = true;
        state.errorOffer = err;
    },
    signOfferDataCreateBegin(state) {
        state.loadingSignOffer = true;
        state.errorSignOffer = false;
    },
    signOfferDataCreateSuccess(state) {
        state.loadingSignOffer = false;
    },
    signOfferDataCreateErr(state, err) {
        state.loadingSignOffer = false;
        state.errorSignOffer = err;
    },
    cleanOffer(state) {
        state.offer = null;
    },
};
